.#{shared-var("PageTemplateBS") }-#{shared-var("BuyingGuidePageBS") } {
  p {
    @extend .mb-4;
  }

  h1,
  h2,
  h3 {
    @extend .font-weight-bold;
    font-size: inherit;
  }

  h2 {
    font-size: 1.5rem;
  }

  h4 {
    @extend .m-0;
    @extend .position-absolute;
    top: 1.5rem;
  }

  .#{shared-var("TitledParagraphBS")},
  .#{shared-var("MediaBS")} {
    @extend .mb-4;

    &-title,
    &-text {
      @extend .px-0;
    }

    &-title {
      @extend .mt-3;
      @extend .h3;
      @extend .font-weight-bold;
    }
  }

  .#{shared-var("NewspaperJumpBS")} {
    @extend .mx-0;
    @extend .px-0;

    .#{shared-var("TitledParagraphBS")} {
      @extend .p-3;
      @extend .bg-light;

      &-title {
        @extend .h5;
      }
    }
  }

  .featured-product {
    .#{shared-var("MediaBS")} {
      &-image {
        @include media-breakpoint-down(sm) {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }

        img {
          max-width: 100% !important;
          min-width: 100% !important;
        }
      }
      &-title {
        @extend .h5;
      }
      &-text {
        button {
          @include media-breakpoint-down(sm) {
            margin-left: auto !important;
            margin-right: auto !important;
          }
        }
      }
      &-body {
        @extend .px-0;

        a:has(button) {
          @extend .text-decoration-none;
          button {
            @extend .btn-light;
            @extend .d-block;
            @extend .mt-2;
            @extend .font-weight-bold;
          }
        }
      }
    }
  }

  .#{shared-var("LayoutBS")}-items {
    .#{shared-var("ProductCollapsibleFAQBS")} {
      .#{shared-var("CollapsibleContainerBS")} {
        @extend .position-relative;
        @extend .mx-0;
        @extend .px-0;
        left: 0;
      }
    }
  }

  .#{shared-var("ProductCollapsibleFAQBS")}-text.#{shared-var("TitledParagraphBS")} {
    @extend .pt-3;
  }

  .#{shared-var("ImageFootnoteCardDeckBS")} {
    .top-image {
      height: calc(100% - 1rem);
      img {
        @extend .w-100;
      }
    }
    .card-footer {
      @extend .bg-light;
      @extend .text-dark;
      @extend .text-center;
      h3 {
        font-size: 1rem;
      }
    }
  }
}
