$total: ();

// set a height of the given class to boost little bit the page CLS
$min-heights: (
  ControlledTabsBS: (
    xs: 100,
    sm: 100,
    md: 100,
    lg: 100
  ),
  ProductRatingBS: (
    xs: 87
  ),
  price-wrapper: (
    xs: 218,
    sm: 274,
    md: 274,
    lg: 274
  ),
  image-gallery-wrapper: (
    xs: 117
  ),
  ImageCarouselBS: (
    xs: 200,
    sm: 200,
    md: 400,
    lg: 600
  ),
  // ProductSliderBS: (
  //   xs: 500,
  //   sm: 500,
  //   md: 589,
  //   lg: 638
  // )
);

// Set the given attribute value of the class given by key
@mixin set-attr($key, $attr, $add-total: true, $map: false) {
  $breakpoints: ();
  @if $map {
    $breakpoints: map-merge($breakpoints, $map);
  } @else {
    @if map-has-key($min-heights, $key) {
      $breakpoints: map-merge($breakpoints, map-get($min-heights, $key));
    }
  }

  @each $breakpoint, $value in $breakpoints {
    @include media-breakpoint-up($breakpoint) {
      #{$attr}: #{$value}px !important;

      @if $add-total {
        @if map-has-key($total, $breakpoint) {
          $total: map-merge(
            $total,
            (
              $breakpoint: $value + map-get($total, $breakpoint)
            )
          ) !global;
        } @else {
          $total: map-merge(
            $total,
            (
              $breakpoint: $value
            )
          ) !global;
        }
      }
    }
  }
}

// Set the min-height of the class given by key
@mixin set-min-height($key, $add-total: true, $map: false) {
  @include set-attr($key, min-height, $add-total, $map);
}

// Set the height of the class given by key
@mixin set-height($key, $add-total: true, $map: false) {
  @include set-attr($key, height, $add-total, $map);
}

.#{shared-var("ProductBS")} {
  @extend .mt-md-3;

  &[id] {
    @extend .px-0;
    @extend .px-lg-3;
  }

  .#{shared-var("ControlledTabsBS")} {
    &-wrapper {
      @extend .mt-3;
      @include set-min-height("ControlledTabsBS");

      a[role="tab"].nav-item {
        &.active {
          @extend .font-weight-bold;
        }
      }
      h2 {
        font-size: inherit;
        font-weight: inherit;
        @extend .mb-0;
      }

      @extend .border-bottom;

      .table.table-sm {
        tr {
          @extend .text-break;
        }
      }
    }
    &-tabitem {
      @extend .border-top;
      @extend .border-left-0;
      @extend .border-right-0;
      @extend .border-bottom-0;
    }
    .card {
      @extend .border-0;

      .card-header {
        background-color: unset !important;
      }
    }
  }

  .#{shared-var("ProductRatingBS")} {
    &-wrapper {
      @include set-min-height("ProductRatingBS");
    }
  }

  &-price-wrapper {
    @include set-min-height("price-wrapper");
  }

  .image-gallery-wrapper {
    @extend .pt-5;

    @include set-min-height("image-gallery-wrapper");

    .carousel-control-prev,
    .carousel-control-next {
      & > div {
        @extend .text-dark;
        @extend .bg-transparent;
      }
    }
  }

  &-#{shared-var("RatingWidgetBS")} {
    .btn-link {
      @extend .text-dark;
    }
  }

  .#{shared-var("ProductTitleBS")} {
    //@extend .text-center;
  }

  .#{shared-var("FavoriteBS")} {
    @extend .position-absolute;
    right: 0;
    top: 0;
  }

  .#{shared-var("ProductPriceBS")} {
    @extend .py-3;

    &.row {
      @extend .bg-light;
      @extend .rounded;
      @extend .m-0;
      @extend .m-sm-3;
      @extend .p-3;
      & > .col {
        @extend .p-0;
        @extend .p-sm-3;
      }
    }

    &-actual {
      font-size: 1.5rem;
    }
  }

  &.#{shared-var("PaymentMethodIconsBS")} {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-around;
    @extend .mr-lg-3;

    text-align: center !important;

    @include media-breakpoint-up(md) {
      justify-content: end !important;
    }
  }

  &.#{shared-var("ImageCarouselBS")} {
    background-color: $menu-bg;
    @include media-breakpoint-down(sm) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .carousel-inner {
      @extend .mx-5;
      @extend .w-auto;
    }

    .carousel-control-prev,
    .carousel-control-next {
      & > div {
        @extend .text-dark;
        @extend .bg-transparent;
      }
    }

    &-item {
      .loading,
      img,
      video {
        @extend .w-100;
        @include set-height("ImageCarouselBS");
      }

      img {
        cursor: zoom-in;
      }
    }
  }

  .#{shared-var("ProductSliderBS")} {
    @include set-min-height("ProductSliderBS");
  }

  .abc {
    @extend .mt-2;

    .col-3 {
      @extend .px-0;

      .product-price {
        @extend .px-0;
      }
    }
    .col-sm-12 {
      .product-price {
        @extend .px-0;
      }
    }

    .product-price {
      //@extend .text-sm-left;
      //@extend .text-right;

      & > .row > .col-4 {
        @extend .pr-0;
      }
    }

    .product-price-actual {
      @extend .text-left;
      @extend .text-md-right;
    }

    .product-price-old {
      @extend .w-100;
      @extend .text-right;

      @include media-breakpoint-down(sm) {
        text-align: left !important;
      }
    }

    @include media-breakpoint-down(sm) {
      .stock-status {
        font-size: 0.75rem;
      }
    }
  }

  .variant {
    @extend .d-flex;
    @extend .flex-column;
    label {
      font-size: 0.75rem;
    }
    select {
      height: 2.4rem;
    }
  }

  .shipping-warranty {
    .row {
      @extend .my-3;
    }
    .col {
      @extend .px-0;
    }

    .row > div:first-child {
      background-color: $menu-bg !important;

      ul {
        @extend .px-2;
        @extend .py-4;
        @extend .my-0;
        list-style-type: none;
      }
      li {
        @extend .align-items-center;
        line-height: 2rem;
        &::before {
          @extend .d-inline-flex;
          @extend .justify-content-center;
          @extend .align-items-center;
          @extend .text-center;
          width: 1.25rem;
          height: 1.25rem;
          content: "✓";
          border-radius: 50%;
          @extend .bg-success;
          @extend .text-light;
          @extend .mr-2;
        }
      }
    }
    .row > div:last-child {
      @extend .pt-0;
      @extend .px-0;
      @include media-breakpoint-up(md) {
        padding-top: 1.25rem !important;
      }
    }

    .embed-responsive {
      @include media-breakpoint-up(sm) {
        &::before {
          padding-top: percentage(divide(3, 4));
        }
      }
      @include media-breakpoint-up(md) {
        &::before {
          padding-top: percentage(divide(9, 16));
        }
      }
    }
  }

  .#{shared-var("EnergyClassBS")}-wrapper {
    height: 2rem;
    margin-bottom: 2rem;
  }
}

.#{shared-var("ProductPageBS")} {
  @include set-min-height($key: null, $map: $total, $add-total: false);
}
