.#{shared-var("ProductBrandContainerBS")} {
  &-image {
    @extend .h-100;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .text-center;

    &.layout-vertical {
      @extend .text-left;
    }

    picture {
      @extend .w-100;
      img {
        @extend .w-100;
        max-width: 50%;
        max-height: 50%;
      }
    }
  }
  .title-text-container-title {
    h3 {
      font-size: inherit;
      @extend .font-weight-bold;
    }
  }
}
