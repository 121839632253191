.#{shared-var("NotificationBarBS")} {
  &-wrapper {
    button.close {
      @extend .text-light;
      @extend .font-weight-light;
      text-shadow: none !important;
      opacity: 1 !important;
    }
  }
}
