:root {
  $default-progress-color: $menu-bg; //$gray-300;
  $default-thumb-color: darken($default-progress-color, 20);
  $default-progress-color: darken($default-progress-color, 40);

  --histogram-bin-color: #{darken($default-progress-color, 15)};
  --histogram-bin-null-color: #{lighten($default-progress-color, 2)};

  --progress-color-default: $default-progress-color;
  --progress-color-selected: $default-progress-color;
  --progress-color-stripped-start: $default-progress-color;
  --progress-color-stripped-stop: #{lighten($default-progress-color, 20)};
  --progress-height: 0.5rem; //rem

  --thumb-color: #{$default-thumb-color};
  --thumb-color-hover: #{darken($default-thumb-color, 20)};
  --thumb-color-focus-shadow: #{darken($default-thumb-color, 25)};
  --thumb-color-focus: #{darken($default-thumb-color, 30)};
  --thumb-height: 1.5; //rem
  --thumb-width: 1rem; //rem
}

.#{shared-var("CustomFormRangeBS")} {
  $histogram-bin-color: var(--histogram-bin-color);
  $histogram-bin-null-color: var(--histogram-bin-null-color);

  $progress-color-default: var(--progress-color-default);
  $progress-color-selected: var(--progress-color-selected);
  $progress-color-stripped-start: var(--progress-color-stripped-start);
  $progress-color-stripped-stop: var(--progress-color-stripped-stop);
  $progress-height: var(--progress-height);

  $thumb-color: var(--thumb-color);
  $thumb-color-hover: var(--thumb-color-hover);
  $thumb-color-focus: var(--thumb-color-focus);
  $thumb-color-focus-shadow: var(--thumb-color-focus-shadow);
  $thumb-height: var(--thumb-height);
  $thumb-width: var(--thumb-width);

  $top-offset: 1rem; //rem

  min-height: calc(#{$thumb-height} + #{$top-offset} + 1rem);

  &-wrapper {
    @extend .border-bottom;
    @extend .w-100;
    @extend .pb-2;
    @extend .mb-2;
    @extend .mx-0;
    @extend .px-4;

    $border-color: $gray-200;
    border-image: linear-gradient(
        to right,
        $border-color,
        $gray-500,
        $border-color
      )
      1;
  }

  position: relative !important;
  //@extend .position-relative;
  @extend .w-100;
  @extend .mb-2;
  @extend .mx-0;
  @extend .mt-4;

  &.disabled {
    @extend .text-muted;
    cursor: not-allowed !important;
  }

  @mixin thumb-shape() {
    width: #{$thumb-width};
    border-radius: 10%;

    &.circle {
      width: #{$thumb-height};
      border-radius: 50%;
    }

    &.square {
      width: #{$thumb-height};
      border-radius: 10%;
    }

    &.stripped {
      @extend .text-center;

      &::before,
      &::after {
        content: "|";
        color: $progress-color-default !important;
      }
    }
  }

  .track {
    @extend .cursor-pointer;
    top: 0;
    left: 0;
    height: #{$progress-height};
    background-color: $progress-color-selected;
  }

  .min-progress,
  .max-progress {
    @extend .position-absolute;
    z-index: 2;
    background-color: $progress-color-default;

    /* stripes */
    &.stripped {
      background: repeating-linear-gradient(
        45deg,
        $progress-color-stripped-start,
        $progress-color-stripped-start 5px,
        $progress-color-stripped-stop 5px,
        $progress-color-stripped-stop 10px
      );
    }

    left: 0;
    top: 0;
    width: 0;
    height: #{$progress-height};
  }

  .thumb {
    @extend .position-absolute;
    @extend .cursor-pointer;
    z-index: 3;
    background-color: $thumb-color !important;

    /* adjust the width/height/radius for different shapes*/
    top: calc(-1 * (#{$thumb-height} - #{$progress-height}) / 2);
    height: #{$thumb-height};

    @include thumb-shape();

    &:focus {
      box-shadow: 0 0 1rem $thumb-color-focus-shadow;
      background-color: $thumb-color-focus !important;
      cursor: grabbing !important;
    }

    &:hover {
      background-color: $thumb-color-hover !important;
      cursor: grab;
    }
  }

  &.disabled .thumb {
    cursor: not-allowed !important;

    &:focus {
      box-shadow: none;
    }
  }

  .wrapper,
  .value {
    @extend .position-absolute;
    top: calc(#{$top-offset}/ 1.2);
  }

  .wrapper {
    @extend .w-100;
    min-height: 3rem;
  }

  .min-value {
    @extend .text-left;
    left: 0;
  }

  .max-value {
    @extend .text-right;
    @extend .float-right;
    right: 0;
  }

  span.value {
    &.min-value,
    &.max-value {
      @extend .font-weight-bold;
      font-size: 0.9rem !important;
    }
  }

  .thumb-tooltip {
    @extend .text-black-50;
    @extend .position-absolute;
    top: calc(#{- 1.5 * (#{$thumb-height} - #{$progress-height}) / 2});
    display: none; // do not use .d-none class !!!
  }

  .histogram {
    @extend .w-100;
    @extend .position-absolute;

    height: 1.5rem;
    top: -0.5rem;

    .bin {
      &.on {
        background-color: $histogram-bin-color;
        &:hover {
          @extend .bg-dark;
        }
      }
      &.off {
        background-color: $histogram-bin-null-color;
      }

      @extend .d-inline-block;
      @extend .cursor-pointer;

      min-height: 0.25rem;
    }
  }
}
