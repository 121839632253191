///////////////////////////////////////////////////////////////////////////////
//
// This is the App global style. Cursors, fonts, text style, etc goes here...
//
///////////////////////////////////////////////////////////////////////////////

.fade {
  @include fade-it;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}

// we need a mixin (not only the class) because it is included within an @include block
@mixin sticky-top {
  // fallback for older Webkit engines
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: $zindex-sticky;
}

// the Bootstrap sticky-top has an issue, so fallback to own implementation
// see also: https://github.com/twbs/bootstrap/issues/25295
.sticky-top {
  @include sticky-top;
}

.position-unset {
  position: unset !important;
}

th {
  @extend .align-middle;
  @extend .text-center;
}

@keyframes scroll-in {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes scroll-out {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-100%);
  }
}

@keyframes scroll-left {
  0% {
    transform: translateX(calc(100vw - 100%));
  }
  100% {
    transform: translateX(-100%);
  }
}

.dotdot:after {
  animation: dotdot 1.5s linear infinite;
  content: "";
}

@keyframes dotdot {
  0%,
  20% {
    content: ".";
  }
  40% {
    content: "..";
  }
  60% {
    content: "...";
  }
  90%,
  100% {
    content: "";
  }
}

button.load-more.btn-secondary {
  background-color: $load-more-button-bg !important;
}
