.#{shared-var("ProductCollapsibleFAQBS")} {
  @extend .py-3;

  .#{shared-var("CollapsibleContainerBS")}-toggler {
    @extend .border-bottom;
  }

  &-title::before {
    content: "- ";
  }

  &.container {
    @extend .px-0;
    @extend .px-sm-3;
  }

  label.title {
    h3 {
      font-size: inherit;
      @extend .font-weight-bold;
    }
  }
}
