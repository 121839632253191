.#{shared-var("MediaBS")} {
  &.container {
    $padding-size: 4;
    padding-top: map-get($spacers, $padding-size);
    padding-bottom: map-get($spacers, $padding-size);
  }

  .nav-link {
    @extend .p-0;
  }

  h1 {
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }

  &-title {
    @extend .font-weight-bold;
    @extend .text-center;
    @extend .text-md-left;
  }
  &-body {
    //@extend .my-auto;
    @extend .text-justify;
  }

  &-image {
    @extend .mb-3;

    // especially usefull for SVG images
    picture {
      @extend .w-100;
      @extend .text-center;
      @extend .align-self-start;
    }

    &.wrap-around {
      @extend .mr-3;
      @extend .mb-3;

      @include media-breakpoint-down(sm) {
        width: 100% !important;
        img {
          height: 100% !important;
        }
      }

      @include media-breakpoint-up(md) {
        float: left !important;
      }
    }
  }
}
