.#{shared-var("PageFooterBS")} {
  z-index: $zindex-sticky !important;

  .inner {
    @include media-breakpoint-up(xl) {
      padding-left: 88px !important;
      padding-right: 88px !important;
      max-width: $desktop-width !important;
      // width: $desktop-width !important;
    }
  }

  @include footer-100;

  .#{shared-var("FooterBrandBS")} {
    @include footer-100;
  }

  & * {
    @extend .bg-transparent;
    color: $dark;

    .#{shared-var("FooterBrandBS")} {
      @extend .bg-secondary;
    }
  }
}
