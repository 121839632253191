.#{shared-var("ShoppingCartBS")} {
  @extend .px-0;

  .alert {
    &.alert-warning {
      color: $shopping-cart-alert-warning-fg !important;
      background-color: $shopping-cart-alert-warning-bg !important;
      border-color: $shopping-cart-alert-warning-border !important;
    }
  }
  &-guide {
    &-thumbnail {
      img {
        @extend .d-block;
        @extend .mx-auto;

        // fallback for older browsers
        max-width: 100%;
        max-height: 100%;

        // modern browser support
        max-width: stretch;
        //max-height: stretch;

        object-fit: contain !important;
      }
    }
  }

  &-items,
  &-title {
    @extend .w-100;
  }

  &-items {
    @extend .mx-0;

    & > .col {
      @extend .px-0;
    }
  }

  &-comment-coupon {
    @extend .px-0;
    @extend .mx-auto;

    .#{shared-var("TextareaCardBS")},
    .#{shared-var("OrderCouponWidgetBS")} {
      @extend .border-0;
      background-color: $menu-bg;
    }

    .#{shared-var("OrderCouponWidgetBS")} {
      @extend .w-100;
      @extend .m-0;
      @extend .p-4;

      button {
        @extend .w-100;
      }
    }

    .#{shared-var("CollapsibleContainerBS")}-toggler > div {
      @extend .font-weight-normal;
    }
  }

  &-subtotal {
    &-gross,
    &-discount,
    &-vat-value,
    &-discounted-value {
      &.calculating {
        text-shadow: 0px 0px 3px;
      }

      @extend .d-flex;
      @extend .justify-content-between;
    }

    &-discounted-value {
      @extend .font-weight-bold;
      @extend .h5;

      & > span:last-child {
        @extend .ml-5;
      }
    }
  }
}
