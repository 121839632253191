.#{shared-var("ProductStockStatusBS")} {
  &.out-of-stock,
  &.available,
  &.limited {
    @extend .d-flex;
    @extend .align-items-center;

    &::before {
      @extend .d-inline-block;
      @extend .mr-1;
      @extend .rounded-circle;
      width: 4px;
      height: 4px;
      content: "";
    }
  }
  &.out-of-stock::before {
    @extend .bg-warning;
  }

  &.available::before {
    @extend .bg-success;
  }

  &.limited::before {
    @extend .bg-warning;
  }
}
