.#{shared-var("PageBreadcrumbBS")} {
  .breadcrumb {
    @extend .mx-auto;
    max-width: $desktop-width !important;
    background-color: inherit !important;
    &-item {
      color: $breadcrumb-fg;
      &::before {
        color: $breadcrumb-divider-color;
      }
      &.active {
        color: $breadcrumb-active-color;
      }
      &.is-home {
        display: $breadcrumb-display-home;
      }
    }

    @include media-breakpoint-up(xl) {
      padding-left: 88px;
      padding-right: 88px;
    }
  }
}
