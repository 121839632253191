.#{shared-var("PageTemplateBS") }-#{shared-var("CheckoutPageBS") } {
  input,
  textarea,
  .searchable-dropdown-toggle > span {
    @extend .border-0;
  }

  .shopping-cart-item-quantity input {
    @extend .border;
  }

  .step-title {
    font-size: 1.4rem;
    font-weight: 300;
  }

  .#{shared-var("BootstrapFormCheckBS")}.custom-control
    input
    + label.custom-control-label::before {
    border-color: $input-border-color !important;
  }

  .callout-top:not(:first-child) {
    margin-top: 5rem !important;
  }

  .callout-top:first-child {
    margin-top: 1rem !important;
  }

  .callout-top {
    $callout-arrow-height: 2.5rem;
    $callout-arrow-width: 2.5rem;

    background-color: $menu-bg !important;
    @extend .px-3;
    @extend .py-3;

    margin-bottom: 5rem !important;

    &:after {
      @extend .mt-3;
      position: absolute;
      margin: 0 auto; // centered-horizontally

      left: 0;
      right: 0;
      width: 0;
      height: 0;

      border-top: $callout-arrow-height solid $menu-bg; /* adjust color and thickness as needed */
      border-left: $callout-arrow-width solid transparent;
      border-right: $callout-arrow-width solid transparent;

      content: "";
    }
  }

  .#{shared-var("CheckoutPlaceOrderBS") } {
    &-agree-newsletter,
    &-agree-buy-terms {
      @include media-breakpoint-down(md) {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
      }
    }
  }

  .checkout-subtotal {
    // @include media-breakpoint-down(md) {
    //   display: none !important;
    // }
  }
}

.modal-dialog-bs.remove-item {
  .modal-footer {
    button {
      &.btn-danger {
        background-color: $shopping-cart-item-btn-remove !important;
        border-color: darken($shopping-cart-item-btn-remove, 5%) !important;
      }
      &.btn-secondary {
        background-color: $shopping-cart-item-btn-cancel !important;
        border-color: darken($shopping-cart-item-btn-cancel, 5%) !important;
      }
    }
  }
}
