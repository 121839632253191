/* 
  Common shared variables between SASS and JavaScript.

  Each `Component` or `Page` should define its own 
  CSS class prefix. They are automatically exported such that 
  JavaScript can use them directly.

  Example: `ComponentBS : some-component-prefix`

  We use these in SASS via the `shared-var(key)` function.
  
  Example: shared-var("BuyButtonBS") returns "btn-buy"

  We use these in JavaScript by importing this file and then 
  accessing the exported collection.

  Example: 
    import sassVar from "@style-variables";
    ...
    const pageId = sassVar.MyPageVariableBS;
 */

@function map-collect($maps...) {
  $collection: ();

  @each $map in $maps {
    $collection: map-merge($collection, $map);
  }
  @return $collection;
}

$modal-xxl: 1400px !default;

// each component container has its own className
$component-variables-map: (
  ActiveFilterListBS: active-filter-list,
  ActiveFilterSortCompareBS: active-filter-sort-compare,
  AddressBS: address,
  AdminBezierChartBS: admin-bezier-chart,
  AdminServerStatusBS: admin-server-status,
  AdminToolboxBS: admin-toolbox,
  AdminWireframeDebuggingBS: admin-wireframe-debugging,
  AdminUsersBS: admin-users,
  AdminDataGridBS: admin-data-grid,
  AdminJSONEditorBS: admin-json-editor,
  AdminMenuEditorBS: admin-menu-editor,
  AdyenApplePayBS: adyen-apple-pay,
  AdyenBACSBS: adyen-bacs,
  AdyenCardBS: adyen-card,
  AdyenEntercashBS: adyen-entercash,
  AdyenFinishEbankingBS: adyen-finish-ebanking,
  AdyenIdealBS: adyen-ideal,
  AdyenKlarnaPayLaterBS: adyen-klarna-paylater,
  AdyenKlarnaPayNowBS: adyen-klarna-paynow,
  AdyenKlarnaPayOverTimeBS: adyen-klarna-account,
  AdyenPayPalBS: adyen-paypal,
  AdyenSofortBS: adyen-sofort,
  AdyenSwishBS: adyen-swish,
  AdyenTwintBS: adyen-twint,
  AdyenTrustlyBS: adyen-trustly,
  AdyenVippsBS: adyen-vipps,
  BackgroundButtonCardDeckBS: bg-btn-card-deck,
  BaseButtonBS: base-button,
  BasePaymentMethodBS: payment-method,
  BaseShipmentMethodBS: shipment-method,
  BootstrapFormCheckBS: bootstrap-form-check,
  BrandInfoBS: product-brand-info,
  BuyButtonBS: btn-buy,
  CampaignBS: campaign,
  CardDeckImageCenteredFootnoteBS: card-image-centered-footnote,
  CardDeckOverlayRowBS: card-overlay-row,
  CardItemBS: card-item,
  CheckoutOtherOptionsBS: checkout-other-options,
  CheckoutPaymentMethodsBS: payment-methods,
  CheckoutPaymentShipmentBS: checkout-payment-shipment,
  CheckoutPlaceOrderBS: checkout-place-order,
  CheckoutPlaceOrderSuccessBS: checkout-place-order-success,
  CheckoutRelatedProductsBS: checkout-related-products,
  CheckoutShipmentMethodsBS: shipment-methods,
  CheckoutSubtotalBS: checkout-subtotal,
  CollapsibleContainerBS: colapsable-container,
  CollectorGetAddressBS: collector-getaddress,
  ContactAddressBS: contact-address,
  ContactCallBS: contact-call,
  ContactCardDeckBS: contact-card-deck,
  ContactEmailBS: contact-email,
  ContactWorktimeBS: contact-worktime,
  ControlledTabsBS: tabbed-card,
  ControlPanelBS: control-panel,
  CustomFormCheckBS: custom-form-check,
  CustomFormRangeBS: custom-form-range,
  DevToolbarBS: dev-toolbar,
  DiffViewerBS: diff-viewer,
  DropdownGroupMenuBS: dropdown-group-menu,
  DropdownGroupMenuItemBS: dropdown-group-menu-item,
  ElfSightWidgetBS: elfsight-widget,
  EnergyClassBS: energy-class,
  EscapedRouteLinkBS: escaped-router-link,
  ExternalLinkBS: external-link,
  ExternalWidgetBS: external-wdiget,
  FilterWidgetBS: filter-widget,
  FooterBrandBS: footer-brand,
  FooterLinksBS: footer-links,
  FormValidationBS: form-validation,
  GDPRCookieControllerBS: gdpr-cookie-controller,
  GroupedTableBS: grouped-list,
  GrouppedFilterItemsBS: groupped-filter-item,
  GridBS: data-grid,
  HorizontalDescriptionBS: horizontal-description,
  FavoriteBS: favorite,
  IconAlignLeft: icon-left,
  IconAlignRight: icon-right,
  ImageCarouselBS: image-carousel,
  ImageFootnoteCardDeckBS: help-banner,
  ImageLinkBS: image-link,
  ImageMagnifierContainerBS: image-magnifier-container,
  ImagePreviewBS: image-preview,
  ImageSliderBS: image-slider,
  JSONViewerBS: json-viewer,
  LayoutBS: layout,
  LayoutItemBS: layout-item,
  LayoutMainBS: layout-main,
  LazyLoaderBS: lazy-loader,
  LayoutFullWidthBS: layout-full-width,
  LinkItemBS: link-item,
  LogoListBS: logo-list,
  MarqueeWidgetBS: marquee-widget,
  MediaBS: media-object,
  MediaEmbederBS: media-embeder,
  MediaEmbederListBS: media-embeder-list,
  MenuBarBS: menu-bar,
  MenuBarBurgerBS: menu-bar-burger,
  MenuBarButtonBS: menu-bar-button,
  MenuBarHeaderBS: menu-bar-header,
  MenuBarItemBS: menu-bar-item,
  MenuBarToggleBS: menu-bar-toggle,
  MenuBarWideBS: menu-bar-wide,
  // don't use modal-dialog since that is the Bootstrap built-in class!
  ModalDialogBS: modal-dialog-bs,
  NewspaperJumpBS: newspaper-jump,
  NotificationBarBS: notification-bar,
  OffCanvasSidebarBS: off-canvas-sidebar,
  OpenAIModalBS: openai-modal,
  OrderCouponWidgetBS: order-coupon-widget,
  PageBreadcrumbBS: page-breadcrumbs,
  PageComponentEditorBS: page-component-editor,
  PageComponentSelectorBS: page-component-selector,
  PageImageSelectorBS: page-image-selector,
  PageEditorBS: page-editor,
  PageFooterBS: page-footer,
  PageHeaderBS: page-header,
  PageImagePropsBS: page-image-props,
  PageMediaPropsBS: page-media-props,
  PageMediaBreakpointColspanBS: page-media-colspan,
  PageTemplateBS: page-template,
  PageTemplateEditorBS: page-template-editor,
  PageTemplateItemEditorBS: page-template-item-editor,
  PageToolbarBS: page-toolbar,
  PageToolbarButtonBS: page-toolbar-button,
  PageWrapperBS: page-wrapper,
  PaymentBS: payment-component,
  PaymentInvoiceBS: payment-invoice,
  PaymentMethodIconsBS: payment-methods-logo,
  PaymentPaypalBS: payment-paypal,
  PictureBS: picture,
  PictureListBS: picture-list,
  PlaceholderBS: placeholder,
  PreorderButtonBS: btn-preorder,
  ProductAvailabilityBS: product-availability,
  ProductBrandContainerBS: product-brand-container,
  ProductBS: product,
  ProductButtonBS: btn-product,
  ProductButtonsBS: product-button-set,
  ProductCategoryBS: product-category,
  ProductCategoryComparatorBS: product-category-comparator,
  ProductCategoryComparatorItemBS: product-category-comparator-item,
  ProductCategoryComparatorSpecsBS: product-category-comparator-specs,
  ProductCategoryComparatorToolbarBS: product-category-comparator-toolbar,
  ProductCategoryHeaderBS: product-category-header,
  ProductCategoryItemBS: product-category-item,
  ProductCategorySelectorBS: product-category-selector,
  ProductCollapsibleFAQBS: product-collapsible-faq,
  ProductFilterCheckboxInputBS: multi-checkbox-filter,
  ProductPackageBS: product-package,
  ProductPriceBS: product-price,
  ProductRatingBS: product-rating,
  ProductRatingWidgetBS: product-rating-widget,
  ProductReviewContainerBS: product-review,
  ProductReviewWidgetBS: product-review-widget,
  ProductSerieItemBS: product-serie-list-item,
  ProductSerieListBS: product-serie-list,
  ProductSliderBS: product-slider,
  ProductStockStatusBS: product-stock-status,
  ProductTitleBS: product-title,
  ProductTopFeatureBS: product-top-feature,
  RangeFilterInputBS: range-interval-input,
  RatingWidgetBS: rating-widget,
  RouteLinkBS: router-link,
  ScrollToTopBS: scroll-to-top,
  SearchBannerBS: search-banner,
  SearchableDropdownBS: searchable-dropdown,
  SearchEngineBS: search-engine,
  SearchEngineCustomBS: search-engine-custom,
  SEOMetricsModalBS: seo-metrics-modal,
  SiteSettingsMenuBS: site-settings-menu,
  SiteSettingsToolbarBS: site-settings-toolbar,
  ShoppingCartAssistantBS: shopping-cart-guide,
  ShoppingCartBS: shopping-cart,
  ShoppingCartCouponBS: shopping-cart-coupon,
  ShoppingCartItemBS: shopping-cart-item,
  SpinnerBS: spinner,
  TableComponentBS: table-component,
  TableCollapsibleBS: table-collapsible,
  TagButtonBS: tag-button,
  TextareaCardBS: comment-container,
  TitledParagraphBS: title-text-container,
  UIBlockBS: ui-block,
  UnhandledExceptionBS: unhandled-exception,
  UserAddressListBS: user-address-list,
  UserFormValidationBS: user-form-validation,
  UserLoginBS: user-login,
  UserLogoutBS: user-logout,
  UserMenuBarBS: user-menubar,
  UserOrderDetailsBS: user-order-details,
  UserOrderListBS: user-order-list,
  UserPaymentListBS: user-payment-list,
  UserProfileBS: user-profile,
  UserRegistrationBS: user-registration,
  UserResetPasswordBS: user-reset-password
);

// each ribbon style is defined by its own className
$ribbon-variables-map: (
  RibbonAroundBS: ribbon-around,
  RibbonBadgeBS: ribbon-badge,
  RibbonCornerBS: ribbon-corner,
  RibbonFoldedBS: ribbon-folded
);

// each page container has its own className
$page-variables-map: (
  AdminToolboxPageBS: admin-toolbox-page,
  AssemblyGuidePageBS: assembly-guide-page,
  AssemblyInstructionPageBS: assembly-instruction-page,
  B2BOrderingPageBS: b2b-ordering-page,
  BuyInfoPageBS: buy-info-page,
  BuyingGuidePageBS: buying-guide-page,
  CheckoutPageBS: checkout-page,
  CookiePolicyPageBS: cookie-policy-page,
  CustomerServicePageBS: customer-service-page,
  CustomMadePageBS: custom-made-page,
  FavoritePageBS: favorite-page,
  HomePageBS: home-page,
  ImprintPageBS: imprint-page,
  IntegrityPolicyPageBS: integrity-policy-page,
  MediaPageBS: media-page,
  NotFoundPageBS: not-found-error,
  NotFoundProductPageBS: not-found-product-page,
  PlaceOrderPageBS: place-order-page,
  PrintedClothPageBS: printed-pool-cloth-page,
  ProductBrandPageBS: product-brand-page,
  ProductCategoryPageBS: product-category-page,
  ProductPageBS: product-page,
  ProductSeriesPageBS: product-series-page,
  ProjectDependencyGraphPageBS: project-deptree-page,
  SearchResultPageBS: search-result-page,
  UserLoginPageBS: user-login-page,
  UserLogoutPageBS: user-logout-page,
  UserProfilePageBS: user-profile-page,
  UserRegistrationPageBS: user-registration-page,
  UserResetPasswordPageBS: user-reset-password-page
);

// other selectors that are neither components nor pages
$misc-variables-map: (
  CookieRootBS: cookie-root,
  PageBodyBS: page-body,
  RootBS: root,
  HasDropDownBS: has-dropdown,
  SiteRibbonBS: site-ribbon,
  DiscountBS: discount,
  PageBuilderBodyBS: page-builder-body
);

$shared-variables-map: map-collect(
  $component-variables-map,
  $page-variables-map,
  $ribbon-variables-map,
  $misc-variables-map
);

/*
  Export the shared vars to JavaScript

  Usage in JS: 
      import sassVar from "@style-variables";
      
      ...then sassVar.BuyButtonBS should give "btn-buy"
*/
:export {
  @each $var, $value in $shared-variables-map {
    #{$var}: $value;
  }
}
