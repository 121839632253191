.#{shared-var("EnergyClassBS")} {
  //@extend .text-right;
  //@extend .w-100;
  @extend .h-100;

  // min-width: 3rem;
  font-family: sans-serif;

  &.title {
    @extend .text-dark;
    @extend .font-weight-bold;
    @extend .text-nowrap;
    @extend .d-block;
    // @extend .text-decoration-underline;
    font-size: 0.6rem;
    height: auto;
    min-height: auto;
  }

  &-link {
    @extend .p-0;
    @extend .text-right;
  }
}
