.#{shared-var("ProductPriceBS")} {
  white-space: nowrap;

  @extend .px-0;

  .col {
    @extend .px-0;
  }

  &-actual,
  &-old {
    @extend .align-self-center;
    @extend .px-0;
  }

  &-actual {
    @extend .w-100;
    @extend .font-weight-bold;
    color: $dark;
    font-size: 1.5rem;
  }

  &-old {
    @extend .text-danger;
  }

  .#{shared-var("EnergyClassBS")}-wrapper {
    @extend .text-right;
  }
}
