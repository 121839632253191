// the page body content enclosed between the page-header and page-footer
.#{shared-var("PageBodyBS")} {
  @extend .py-3;
  @extend .px-2;
  min-height: 66vh;
  // padding-left: 88px !important;
  // padding-right: 88px !important;

  &.container {
    @extend .position-relative;
    // @extend .shadow-sm;

    @include media-breakpoint-up(xl) {
      // max-width: $desktop-width !important;
      max-width: 100% !important;
      // padding: 0 !important;
      // width: $desktop-width !important;
    }

    &.maximized {
      @extend .mw-100;
      @extend .w-100;
      @extend .mx-0;
    }
  }
}
