.#{shared-var("CustomerServicePageBS") } {
  .media-object {
    h1 {
      @extend .text-left;
    }
  }
  .media {
    @extend .h-100;
    @extend .justify-content-center;
    @extend .align-items-center;
    & > div {
      @extend .mx-2;
    }
  }

  .media-object-image {
    @extend .pb-4;
  }

  @include media-breakpoint-down(md) {
    .media-object-image {
      display: none !important;
    }

    .title-text-container {
      padding-left: 1.25rem !important;
      padding-right: 1.25rem !important;
    }
  }
}
