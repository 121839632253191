.#{shared-var("MenuBarHeaderBS")} {
  @extend .w-100;
  @extend .sticky-top;
  @extend .overflow-hidden;
  //top: 32px;

  @include media-breakpoint-down(sm) {
    background-color: $menu-bg !important;
  }

  .navbar-brand {
    padding-bottom: $navbar-brand-padding-y/2 !important;
    margin-right: unset;
  }

  &-buttons {
    @extend .navbar-brand;
    @extend .float-right;
    @extend .justify-content-end;

    @include media-breakpoint-up(md) {
      max-width: 50%;
      width: 50%;
    }

    & > .#{shared-var("HasDropDownBS")} {
      @extend .d-inline-flex;

      .dropdown-toggle::after {
        @extend .cursor-pointer;
        @extend .d-inline-block;

        height: 1rem !important;

        // @include media-breakpoint-down(md) {
        vertical-align: -0.5rem;
        //}
      }

      @include media-breakpoint-down(xs) {
        display: none !important;
      }
    }
  }

  &-brand-top {
    @extend .d-flex;
    @extend .bg-white;
    @extend .text-center;
  }

  &-brand {
    @extend .h-100;
    @extend .float-left;
    @extend .position-absolute;

    &,
    .loading {
      min-height: 58px !important;

      @include media-breakpoint-up(md) {
        min-height: 38px !important;
      }
      @include media-breakpoint-up(lg) {
        min-height: 58px !important;
      }
    }

    &:not(.mobile) {
      max-width: 49%;
      top: 50%;
      transform: translateY(-50%);
      img {
        object-position: 0 50% !important;
      }
    }

    &.mobile {
      @extend .position-static;
    }

    img {
      @extend .w-100;
      @extend .h-100;

      object-fit: scale-down !important;
      min-width: auto !important;
    }

    //@extend .pl-0;
  }

  nav.navbar {
    background-color: inherit !important;
    @extend .px-0;
    @extend .py-1;

    // &.navbar-dark {
    //   @extend .bg-dark;
    //   @extend .text-white;
    // }

    // &.navbar-light {
    //   @extend .bg-white;
    //   @extend .text-dark;
    // }

    & > div.buttons {
      @extend .clearfix;
      @extend .vw-100;
    }
  }
}
