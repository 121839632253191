.#{shared-var("ProductSerieItemBS")} {
  @extend .my-4;

  .show-more a {
    @extend .d-block;
    @extend .h-100;
    padding-top: 4px;
  }

  &-title {
    //    @extend .text-center;
    @extend .font-weight-bold;
    @extend .h6;
  }

  &-image-wrapper {
    img {
      @extend .w-100;
      min-height: unset !important;
    }
  }

  &-summary-wrapper {
    @extend .d-flex;
    @extend .align-self-stretch;
  }

  a:hover {
    @extend .text-decoration-none;
  }

  &-text {
    @extend .text-left;

    // &.text-truncated {
    //   $text-truncate-breakpoints: (
    //     xs: 3,
    //     sm: 3,
    //     md: 3,
    //     lg: 3,
    //     xl: 3
    //   ) !default;

    //   @each $name, $lines in $text-truncate-breakpoints {
    //     @include media-breakpoint-only($name) {
    //       @include text-truncate-lines(
    //         $lines,
    //         $bg-color: $product-series-summary-bg
    //       );
    //     }
    //   }
    // }
  }

  &-subtext {
    @extend .bg-secondary;
  }

  &-header {
    button {
      @extend .font-weight-bold;
      @extend .px-3;
      font-size: 1rem !important;
    }
  }

  .#{shared-var("ProductPriceBS")} {
    & > .row {
      @extend .justify-content-start;
    }
  }
}
