.#{shared-var("ContactCardDeckBS")} {
  //@extend .py-5;
  @extend .my-4;
  //@extend .border-bottom;

  p {
    @extend .mb-1;
  }

  & > .row {
    @extend .mx-0;
    //@extend .justify-content-between;
    & > div {
      @extend .p-2;

      .#{shared-var("ContactWorktimeBS")},
      .#{shared-var("ContactAddressBS")},
      .#{shared-var("ContactEmailBS")},
      .#{shared-var("ContactCallBS")} {
        @extend .p-2;
        background-color: $menu-bg !important;
      }
    }
  }

  // .#{shared-var("ContactWorktimeBS")},
  // .#{shared-var("ContactEmailBS")} {
  //   height: 50% !important;
  // }

  // .#{shared-var("ContactAddressBS")},
  // .#{shared-var("ContactCallBS")} {
  //   height: 50% !important;
  // }
}
