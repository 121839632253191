.#{shared-var("SearchableDropdownBS")} {
  &-menu {
    @extend .w-100;
    @extend .overflow-hidden;

    z-index: $zindex-dropdown !important;

    & > input {
      @extend .mx-auto;

      //@extend .sticky-top;

      width: 98% !important;
    }

    & > ul {
      @extend .mx-1;
      @extend .my-0;
      @extend .cursor-pointer;
      @extend .overflow-auto;
      max-height: 200px !important;

      li {
        &:not(.active) {
          &:not(:current) {
            span:first-child {
              @extend .text-primary;
            }
          }
        }
      }

      $max-nest-levels: 6;

      & > .dropdown-header {
        @extend .font-weight-bold;
        @extend .text-primary;
        @extend .px-3;
      }

      // generate nest-level-N classes
      @mixin nestLevel($level) {
        $nest-level-padding: 1.5;
        $nest-level-step: 0.5;

        padding-left: #{$nest-level-padding + $nest-level-step * $level}em !important;
      }

      @for $i from 0 to $max-nest-levels {
        & .nest-level-#{$i} {
          @include nestLevel($i);
        }
      }
    }
  }
}
