.#{shared-var("MenuBarBurgerBS")} {
  &.container-fluid {
    //@extend .position-fixed;
    @extend .w-100;
    @extend .h-100;
    @extend .bg-white;
    @extend .overflow-auto;
  }

  &-header {
    background-color: $menu-bg !important;
  }

  &-wrapper {
    @extend .bg-white;
    @extend .position-fixed;
    @extend .w-100;
    top: 0;
    height: 100vh !important;
    z-index: $zindex-sticky + 1;
  }

  .MenuBurgerList {
    @extend .pl-1; // 2px padding-left
    @extend .list-unstyled;

    // &:first-child {
    //   font-size: 12px;
    //   height: 40px;
    // }
  }

  .MenuBurgerListItem {
    @extend .border-bottom;
    border-color: $menu-bg;
    line-height: 2.5;

    .icon {
      @extend .position-absolute;
      @extend .mt-3;
      right: 2rem;
    }

    &.separator {
      line-height: 5;
    }

    & > [role="navigation"],
    & > [role="menuitem"] {
      @extend .d-inline-block;
      @extend .w-100;
    }
  }
}
