.#{shared-var("GroupedTableBS")} {
  &-icon {
    @extend .text-muted;
  }

  &-group {
    h3 {
      //font-size: inherit;
      @extend .font-weight-bold;
    }
  }

  &.table-striped tbody tr {
    td {
      @extend .border-0;
      @extend .py-2;
    }

    &:nth-of-type(even) {
      background-color: $table-accent-bg;
    }

    &:nth-of-type(odd):not(.#{shared-var("GroupedTableBS")}-showMore) {
      @extend .bg-transparent;
    }

    td {
      @extend .pl-4;

      &.#{shared-var("GroupedTableBS")}-group {
        @extend .pl-1;
      }
    }
  }

  @include media-breakpoint-down(md) {
    tr > td:last-child {
      text-align: right !important;
    }
  }
}
