.#{shared-var("ProductTitleBS")} {
  @extend .p-0;

  &-title {
    @extend .text-dark;
    @extend .h1;
    //font-size: 1rem !important;
    font-weight: 500 !important;
  }

  &-subtitle {
    @extend .text-dark;
    @extend .mt-2;

    ul {
      @extend .pl-3;
    }
  }

  &-brand {
    @extend .h4;
    @extend .font-weight-normal;
  }
}
