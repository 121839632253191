.#{shared-var("ImageCarouselBS")} {
  &-item {
    .#{shared-var("LazyLoaderBS")} {
      @extend .d-block;
    }
    img {
      object-fit: contain !important;
    }
  }

  .carousel-indicators {
    @extend .mx-auto;
    width: fit-content;
  }
}
