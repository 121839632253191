.#{shared-var("ProductPackageBS")} {
  @extend .text-center;

  &.container {
    @extend .px-0;
  }

  .carousel-item {
    & > .row > {
      @extend .no-gutters;
      @extend .flex-nowrap;

      & div.image-slider {
        &:not(:empty) {
          @extend .bg-light;
        }
        @extend .px-0;
        @extend .mx-1;
        @extend .flex-shrink-1;
        // @extend .border;
      }
    }
  }

  & > .row > div {
    @extend .mb-2;
    @extend .col-lg-3;
    @extend .col-sm-6;
    @extend .col-12;

    //@extend .mx-1;
    @extend .bg-light;
  }

  .item {
    &.container {
      @extend .px-1;
      @extend .border-0;
    }

    @extend .h-100;

    @extend .border;
    @extend .rounded;

    .brand-title {
      @extend .text-muted;
      //@extend .text-uppercase;
      @extend .font-weight-bolder;
      @extend .py-1;
      font-size: 0.75rem;
    }

    .product-title-title {
      font-size: 0.75rem;
    }

    .thumbnail {
      & > div.nav-link {
        @extend .px-0;
      }

      img {
        @extend .w-100;
        @extend .h-100;
        object-fit: contain !important;
        //min-height: auto !important;
        min-width: auto !important;
      }
    }
  }

  .#{shared-var("ProductTitleBS")}-title {
    font-size: initial;
  }
}
