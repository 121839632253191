.#{shared-var("AdminDataGridBS")} {
  &-wrapper {
    & > .data-loading {
      @extend .position-absolute;
      width: calc(100% + 2rem);
      height: calc(100% + 2rem);
      top: -1rem;
      left: -1rem;

      z-index: $zindex-modal-backdrop;
      background-color: $modal-backdrop-bg;
      opacity: $modal-backdrop-opacity;

      cursor: wait;
    }
  }

  &.#{shared-var("GridBS")} {
    .#{shared-var("GridBS")} {
      // ---  header ---
      &-header {
        @extend .text-center;

        .#{shared-var("GridBS")}-col {
          @extend .px-2;

          &:first-child {
            max-width: 3rem !important;
            input {
              @extend .position-relative;
            }
          }

          &:nth-child(2) {
            max-width: 4rem !important;

            &.col-width-colspan {
              max-width: unset !important;
            }
          }
        }
      }

      // ---  rows ---
      &-row {
        .#{shared-var("GridBS")}-col {
          @extend .px-2;

          &:first-child {
            max-width: 3rem !important;
            input {
              @extend .position-relative;
            }
          }

          &:last-child {
            svg {
              @extend .ml-1;
            }
          }

          &:nth-child(2) {
            max-width: 4rem !important;

            &.col-width-colspan {
              max-width: unset !important;
            }
          }
        }
      }
    }
  }
}
