.#{shared-var("ProductAvailabilityBS")} {
  &-from {
    &-title,
    &-date {
      @extend .text-danger;
      @extend .text-nowrap;
      font-size: 0.75rem;
    }
  }

  &-out-of-stock,
  &-in-stock {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .text-dark;
    font-size: 0.75rem;
    line-height: 1;
    height: 2rem;
  }

  &-out-of-stock::before,
  &-in-stock::before {
    @extend .mr-1;
    @extend .rounded-circle;
    width: 4px;
    height: 4px;
    content: "";
  }
  &-out-of-stock::before {
    background-color: #d1752a;
  }
  &-in-stock::before {
    background-color: $success;
  }
}
