.#{shared-var("CheckoutSubtotalBS")} {
  & > div {
    display: grid;
    justify-content: center;
    @extend .mb-5;
  }

  &-gross,
  &-discount,
  &-shipping,
  &-vat,
  &-total-discounted {
    &.calculating {
      text-shadow: 0px 0px 3px;
    }

    @extend .d-flex;
    @extend .justify-content-between;
    & > span:last-child {
      @extend .ml-5;
    }
  }

  &-total-discounted {
    @extend .font-weight-bold;
    @extend .h5;
  }
}
