.#{shared-var("ShoppingCartAssistantBS")} {
  @extend .p-4;

  &:not(.is-modal) {
    //z-index: $zindex-dropdown - 1 !important; // menu has higher priority
    z-index: $zindex-fixed + 1 !important;

    @extend .shadow;
    @extend .rounded;

    width: 400px;

    @include media-breakpoint-up(xl) {
      right: calc((100vw - #{$desktop-width}) / 2);
    }
    @include media-breakpoint-down(lg) {
      left: calc(100vw - 400px);
    }
    @include media-breakpoint-up(sm) {
      position: fixed !important;
    }

    @extend .sticky-top;

    @include media-breakpoint-down(xs) {
      width: 98%;
      margin-left: 1%;
      margin-right: 1%;
    }

    @include media-breakpoint-down(sm) {
      top: $navbar-height - 2px !important;
    }

    box-shadow: $box-shadow;

    background-color: $dropdown-bg;
  }

  &.is-modal {
    .header {
      @extend .font-weight-bold;
      font-size: 1.75rem !important;

      @include media-breakpoint-down(sm) {
        font-size: 1.25rem !important;
        font-weight: 500 !important;
      }
      svg {
        color: $success !important;
      }
    }
    .cart-item {
      @include media-breakpoint-up(sm) {
        margin-left: $spacer * 3 !important;
        margin-right: $spacer * 3 !important;
        @include media-breakpoint-up(lg) {
          & .container {
            margin-left: $spacer !important;
            margin-right: $spacer !important;
            padding-left: $spacer * 3 !important;
            padding-right: $spacer * 3 !important;
          }
        }
      }
    }

    .#{shared-var("CheckoutRelatedProductsBS")} {
      & h3 {
        @include media-breakpoint-down(sm) {
          font-size: 1.25rem !important;
          font-weight: 700 !important;
        }
      }
    }
    .#{shared-var("ProductSliderBS")} {
      @extend .px-0;
      @extend .px-md-5;

      &-slider {
        .carousel-inner {
          @extend .px-0;
        }
        &-group-item {
          @include media-breakpoint-up(xl) {
            &.col-xl-2 {
              flex: 0 0 20%;
              max-width: 20%;

              .thumbnail img {
                max-height: 128px !important;
              }
            }
          }
        }
      }
    }

    .#{shared-var("ProductPriceBS")} {
      .d-flex.row {
        @extend .mx-0;
      }
    }
  }

  &-thumbnail {
    background-color: $menu-bg;
    img {
      @extend .d-block;
      @extend .mx-auto;

      // fallback for older browsers
      max-width: 100% !important;
      //max-height: 100% !important;

      // modern browser support
      max-width: stretch !important;
      //max-height: stretch !important;
    }
  }

  &-title-price {
    .row:first-child {
      font-weight: 400 !important;
      @include media-breakpoint-down(sm) {
        font-size: 1.1rem !important;
      }
      font-size: 1.25rem !important;
    }
  }
}
