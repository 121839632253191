.#{shared-var("CheckoutPaymentShipmentBS")} {
  .custom-control-label {
    @extend .dark;
  }

  .payment-method-items {
    .#{shared-var("BasePaymentMethodBS")} {
      // @extend .border-top;
      @extend .py-2;

      .#{shared-var("PictureBS")} > img {
        @extend .mx-1;
        object-fit: contain;
      }
    }
  }
}
