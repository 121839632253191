##{shared-var("AdminToolboxPageBS")} {
  &,
  .#{shared-var("AdminToolboxPageBS")},
  .#{shared-var("AdminToolboxBS")},
  .container {
    &:not(.child-object):not(.#{shared-var("AdminToolboxBS")}) {
      @extend .mx-0;
    }
    @extend .w-100;
    //@extend .mw-100;
  }

  @extend .border;
  @extend .rounded;
  @extend .border-light;
  border-style: dashed !important;

  &::after {
    @extend .w-100;
    @extend .text-right;
    @extend .d-block;
    @extend .pr-3;
    content: "Ctrl + double-click to resize";
    color: rgba(0, 0, 0, 0.1) !important;
  }

  &:hover {
    @extend .border-warning;
    &::after {
      color: rgba(0, 0, 0, 0.2) !important;
    }
  }

  // unset link styling on admin page
  a.#{shared-var("EscapedRouteLinkBS")} {
    color: unset !important;

    text-decoration-line: unset !important;

    &:hover {
      @extend .text-decoration-underline;
    }
  }

  .preview-wrapper {
    @include link-style($link-color);
  }

  .flotable-alerts {
    @extend .position-fixed;
    box-shadow: 0.5rem -0.5rem 1rem rgba($black, 0.175);
    right: 1rem;
    bottom: 4rem;
    z-index: 1021;
    @include media-breakpoint-down(md) {
      max-width: 95%;
      max-height: 50%;
    }
    @include media-breakpoint-up(md) {
      max-width: 50vw;
      max-height: 30vh;
    }
    @include media-breakpoint-up(lg) {
      max-width: 30vw;
    }
  }
}
