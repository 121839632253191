.#{shared-var("PageBuilderBodyBS") } {
  // @include media-breakpoint-up(md) {
  //   .container,
  //   .container-fluid {
  //     padding-left: 0 !important;
  //     padding-right: 0 !important;
  //   }
  // }

  .#{shared-var("TitledParagraphBS")} {
    & > .row {
      @include media-breakpoint-up(md) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  .#{shared-var("MediaBS")} {
    @include media-breakpoint-up(sm) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    // @include media-breakpoint-down(sm) {
    //   padding-left: 0 !important;
    //   padding-right: 0 !important;
    // }
    .row > div {
      @include media-breakpoint-up(sm) {
        &:first-child {
          padding-left: 0 !important;
        }
        &:last-child {
          padding-right: 0 !important;
        }
      }
      // @include media-breakpoint-down(sm) {
      //   padding-left: 0 !important;
      //   padding-right: 0 !important;
      // }
    }
  }

  .#{shared-var("ImageSliderBS")} {
    .carousel-item {
      & > .row {
        @include media-breakpoint-up(md) {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }

        .#{shared-var("ImageSliderBS")} {
          // @include media-breakpoint-down(md) {
          //   padding-left: 0 !important;
          //   padding-right: 0 !important;
          // }

          @include media-breakpoint-up(md) {
            &:first-child {
              padding-left: 0 !important;
            }
            &:last-child {
              padding-right: 0 !important;
            }
          }
        }
      }
    }
  }
}
