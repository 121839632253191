.#{shared-var("FilterWidgetBS")} {
  .#{shared-var("CollapsibleContainerBS")} {
    &.groupped-filter-item {
      .#{shared-var("CollapsibleContainerBS")} {
        @extend .px-0;
      }
    }
    > .row:not(.#{shared-var("CollapsibleContainerBS")}-toggler) {
      .custom-form-range-wrapper,
      .multi-checkbox-filter {
        @extend .px-2;
      }
    }

    .#{shared-var("CollapsibleContainerBS")}-toggler {
      label,
      svg {
        color: #000000 !important;
      }
    }
  }

  label[for="privileged_filters-with_files"],
  label[for="privileged_filters-with_flags"],
  label[for="privileged_filters-with_stock"] {
    @extend .text-info;
    &::after {
      content: " (adminOnly)";
    }
  }

  .#{shared-var("BootstrapFormCheckBS")} {
    label::before {
      border-color: unset !important;
    }
  }
}
