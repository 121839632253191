$debugging: #{shared-var("AdminWireframeDebuggingBS")};
$full-width: #{shared-var("LayoutFullWidthBS")};
$ignore-full-width: ignore-#{$full-width};

.#{shared-var("LayoutBS")} {
  &-items {
    $layout-main: #{shared-var("LayoutMainBS")};

    @extend .px-0;

    .container-fluid,
    .container {
      & > .row {
        @extend .mx-0;
        & > .col {
          @extend .px-0;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      &.#{$layout-main}
        > *:not(.position-fixed):not(.#{$full-width}):not(
          .#{$ignore-full-width}
        ) {
        margin-left: auto !important;
        margin-right: auto !important;
        max-width: $desktop-width !important;
        &:not(.no-gap) {
          padding-left: 88px !important;
          padding-right: 88px !important;
        }
      }

      // debugging breakpoint wireframe
      &.#{$layout-main}.#{$debugging}
        > *:not(.position-fixed):not(.#{$full-width}):not(
          .#{$ignore-full-width}
        ) {
        & > * {
          border-left: 1px dashed red !important;
          border-right: 1px dashed red !important;

          // margin-left: 0;
          // margin-right: 0;
        }

        & > table {
          border-collapse: separate;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .#{$full-width} {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: 100% !important;

    background: linear-gradient(to top, $menu-bg, transparent 70%);

    & > * {
      margin-left: auto !important;
      margin-right: auto !important;

      @include media-breakpoint-up(xl) {
        max-width: $desktop-width !important;
        padding-left: 88px !important;
        padding-right: 88px !important;
      }
    }

    // debugging breakpoint wireframe
    &.#{$debugging} {
      @include media-breakpoint-up(xl) {
        & > * {
          & > * {
            &:first-child {
              border-left: 1px dashed red !important;
              border-right: 1px dashed red !important;
            }
            &:last-child {
              border-right: 1px dashed red !important;
            }
          }
        }
      }
    }

    &.media-object {
      & > * {
        display: flex !important;
        justify-content: center !important;
      }
      .media-object-title {
        margin-top: 1rem !important;
      }
      .media-object-body {
        margin-right: 1rem !important;
      }
      .media-object-image {
        margin-left: 1rem !important;
        img {
          max-width: fit-content;
        }
      }
      .media-object-body,
      .media-object-image {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }
}
