.#{shared-var("MarqueeWidgetBS")} {
  @extend .position-relative;
  @extend .mx-0;
  @extend .py-1;
  @extend .py-md-2;
  // @extend .border-bottom;
  background-color: #fafafa;
  font-size: 12px;

  &-item {
    img,
    svg {
      max-height: 29px;
    }

    @extend .my-auto;
    @extend .text-center;
    @extend .px-3;
    @extend .d-none;
    @extend .d-md-flex;
    & > * {
      @extend .w-100;
    }
    &:not(:last-child) {
      @extend .border-right;
    }
  }

  &-carousel {
    //    @extend .p-2;

    &.slide {
      @extend .w-100;
    }

    .carousel-inner {
      @extend .d-flex;
      @extend .align-items-center;
      @extend .text-center;

      min-height: 32px;
    }
  }

  &-scrollable {
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-center;

    &-item {
      @extend .d-flex;
      @extend .position-absolute;

      &.active {
        opacity: 1;
        transform: translateY(0%);
        transition: transform 700ms, opacity 700ms;
      }
      &.inactive {
        opacity: 0;
        transform: translateY(-100%);
        transition: transform 500ms, opacity 500ms;
      }
      &.hidden {
        opacity: 0;
        transform: translateY(100%);
        transition: transform 1000ms;
      }
    }

    &.bottom-top {
    }
  }
}
