.#{shared-var("PictureBS")} {
div {
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .position-relative;

    &.force-thumbnail {
      & > canvas {
        @extend .d-block;
        z-index: 1;

        & + img {
          @extend .invisible;
        }
      }
    }
  }

  img {
    position: inherit;
    object-position: 50% 50%;
    object-fit: cover;

    left: 0;
    top: 0;

    opacity: 1;
    transition: opacity 0.5s ease;
  }

  canvas {
    @extend .position-absolute;
    @extend .h-100;
    filter: blur(0.5rem);
    object-fit: contain;
    object-position: 50% 50%;
  }
}

.force-thumbnail {
  &::before {
    @extend .position-absolute;
    @extend .text-muted;
    @extend .border;
    @extend .rounded;
    @extend .m-1;
    padding: 0 1px !important;
    background-color: rgba($light, 0.75);
    content: attr(data-thumbnail-size) " / " attr(data-image-size);
    font-size: 0.75rem;
    z-index: 9;
    top: 0;
    right: 0;
  }
}

.hover-alt-image-hidden {
  opacity: 0 !important;
}

