.#{shared-var("MenuBarWideBS")} {
  // header menu items
  $navigation-menu-item-shadow: unset;
  $navigation-menu-item-color: unset;
  $navigation-menu-item-hover-color: unset;
  $navigation-menu-item-focus-color: unset;

  // menu content
  $menu-content-shadow: unset; // hsla(206, 22%, 7%, 0.2);
  $menu-content-rounded: false;

  // menu content group items
  $menu-group-item-header-color: unset;
  $menu-group-item-hover-color: unset;
  $menu-group-item-focus-shadow-color: unset;
  $menu-group-item-color: unset;

  button,
  p {
    all: unset;
  }

  a:hover {
    @extend .text-decoration-none;
    // font-weight: 500 !important;
  }

  .active {
    @extend .font-weight-bold;
  }

  &.NavigationMenuRoot {
    @extend .position-relative;
    @extend .d-flex;
    @extend .justify-content-between;
    width: 100vw;
    z-index: $zindex-dropdown;
    //background-color: $menu-bg !important;

    & > div:first-child {
      @extend .w-100;
    }

    &.menu-preview {
      animation: bgColorChange 2s ease-out;
    }
  }

  .NavigationMenuList {
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .py-1; // 4px
    @extend .rounded; // 6px corresponds to rounded class
    @extend .list-unstyled;
    @extend .m-0;

    @if ($navigation-menu-item-shadow!=unset) {
      box-shadow: 0 2px 10px $navigation-menu-item-shadow;
    }

    & > .left-items,
    & > .right-items {
      @extend .d-flex;

      // li:hover {
      //   @extend .border-bottom;
      //   @extend .border-dark;
      // }
    }

    & > .left-items {
      @extend .justify-content-start;
      li:first-child {
        button {
          @extend .pl-0;
        }
      }
    }

    & > .right-items {
      @extend .justify-content-end;
      li:last-child {
        button {
          @extend .pr-0;
        }
      }
    }
  }

  .NavigationMenuTrigger,
  .NavigationMenuLink {
    @extend .p-2; // padding: 8px 12px corresponds to p-2
    // @extend .font-weight-bold;
    @extend .rounded; // 4px corresponds to rounded class
    font-size: 15px;
    line-height: 1;
    outline: none;
    user-select: none;

    @if ($navigation-menu-item-focus-color!=unset) {
      &:focus {
        box-shadow: 0 0 0 2px $navigation-menu-item-focus-color;
      }
    }

    @if ($navigation-menu-item-hover-color!=unset) {
      &:hover {
        background-color: $navigation-menu-item-hover-color;
      }
    }

    @if ($navigation-menu-item-color!=unset) {
      color: $navigation-menu-item-color;
    }
  }

  .NavigationMenuTrigger {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-between;
    gap: 2px;

    &[data-state="open"] {
      @extend .rounded-0;
      @extend .border-bottom;
      @extend .border-dark;
    }
  }

  .NavigationMenuLink {
    @extend .d-block;
    @extend .text-decoration-none;
    // font-size: 15px;
    // line-height: 1;

    // &:hover {
    //   @extend .text-muted;
    // }
  }

  .#{shared-var("MenuBarItemBS")}:has(.NavigationMenuLink) {
    @extend .border-bottom;
    border-bottom-color: transparent !important;
    &:hover {
      @extend .border-dark;
    }
  }

  .NavigationMenuContent {
    @extend .position-absolute;
    @extend .w-100;
    top: 0;
    left: 0;
    // padding-left: 88px;
    // padding-right: 88px;

    animation-duration: 250ms;
    animation-timing-function: ease;

    &[data-motion="from-start"] {
      animation-name: enterFromLeft;
    }
    &[data-motion="from-end"] {
      animation-name: enterFromRight;
    }
    &[data-motion="to-start"] {
      animation-name: exitToLeft;
    }
    &[data-motion="to-end"] {
      animation-name: exitToRight;
    }

    @media only screen and (min-width: 600px) {
      width: auto;
    }
  }

  .NavigationMenuIndicator {
    @extend .d-flex;
    @extend .align-items-end;
    @extend .justify-content-center;
    @extend .overflow-hidden;
    height: 10px;
    top: 100%;
    z-index: 1;
    transition: width, transform 250ms ease;

    &[data-state="visible"] {
      animation: fadeIn 200ms ease;
    }
    &[data-state="hidden"] {
      animation: fadeOut 200ms ease;
    }
  }

  .NavigationMenuViewport {
    @extend .position-relative;
    @extend .w-100;
    @if ($menu-content-rounded==true) {
      @extend .rounded;
    }
    @extend .overflow-hidden;
    transform-origin: top center;
    /* margin-top: 10px; */
    background-color: $menu-bg !important;
    @if ($menu-content-shadow!=unset) {
      box-shadow: $menu-content-shadow 0px 5px 10px -10px,
        $menu-content-shadow 0px 10px 20px -15px;
    }
    height: var(--radix-navigation-menu-viewport-height);
    transition: width, height, 300ms ease;

    &[data-state="open"] {
      animation: scaleIn 200ms ease;
    }
    &[data-state="closed"] {
      animation: scaleOut 200ms ease;
    }
    @media only screen and (min-width: 600px) {
      width: var(--radix-navigation-menu-viewport-width);
    }
  }

  .List {
    @extend .p-3; // 22px padding
    @extend .m-0;
    @extend .list-unstyled;
    @extend .justify-content-start;
    display: grid;
    column-gap: 10px;
  }
  @media only screen and (min-width: 600px) {
    .List.one {
      width: 500px;
      grid-template-columns: 0.75fr 1fr;
    }
    .List.two {
      width: 600px;
      grid-auto-flow: column;
      grid-template-rows: repeat(3, 1fr);
    }
  }

  .ListItemLink {
    @extend .d-block;
    outline: none;
    @extend .text-decoration-none;
    user-select: none;
    @extend .p-3; // 12px padding
    @extend .rounded;
    font-size: 15px;
    line-height: 1;

    @if ($menu-group-item-focus-shadow-color!=unset) {
      &:focus {
        box-shadow: 0 0 0 2px $menu-group-item-focus-shadow-color;
      }
    }
    @if ($menu-group-item-hover-color!=unset) {
      &:hover {
        background-color: $menu-group-item-hover-color;
      }
    }
  }

  .ListItemHeading {
    @extend .font-weight-bold;
    @extend .mb-1; // 5px margin-bottom
    line-height: 1.2;
    @if ($menu-group-item-header-color!=unset) {
      color: $menu-group-item-header-color;
    }
  }

  .ListItemText {
    @extend .font-weight-normal;
    line-height: 2;
    font-size: 12px;

    @if ($menu-group-item-color!=unset) {
      color: $menu-group-item-color;
    }

    a {
      &:hover {
        //  font-weight: 500 !important;
        text-decoration: underline !important;
      }
    }
  }

  // .Callout {
  //   @extend .d-flex;
  //   @extend .justify-content-end;
  //   @extend .flex-column;
  //   @extend .w-100;
  //   @extend .h-100;
  //   @extend .rounded;
  //   @extend .p-4; // 25px padding
  //   @extend .text-decoration-none;
  //   outline: none;
  //   user-select: none;
  //   background: linear-gradient(
  //     135deg,
  //     var(--purple-9) 0%,
  //     var(--indigo-9) 100%
  //   );

  //   &:focus {
  //     box-shadow: 0 0 0 2px var(--violet-7);
  //   }
  // }

  // .CalloutHeading {
  //   @extend .text-white;
  //   @extend .font-weight-bold;
  //   @extend .mt-4; // 16px margin-top
  //   @extend .mb-2; // 7px margin-bottom
  //   font-size: 18px;
  //   line-height: 1.2;
  // }

  // .CalloutText {
  //   color: var(--mauve-4);
  //   font-size: 14px;
  //   line-height: 1.3;
  // }

  .ViewportPosition {
    @extend .position-absolute;
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .w-100;
    top: 100%;
    left: 0;
    perspective: 2000px;

    & > * {
      box-shadow: 0px 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.8);
    }

    max-width: $desktop-width;

    @include media-breakpoint-up(xl) {
      padding-left: 88px;
      padding-right: 88px;
    }
  }

  .CaretDown {
    position: relative;
    color: var(--violet-10);
    top: 1px;
    transition: transform 250ms ease;
  }
  [data-state="open"] > .CaretDown {
    transform: rotate(-180deg);
  }

  .Arrow {
    @extend .position-relative;
    @extend .bg-dark;
    top: 70%;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    border-top-left-radius: 2px;
  }

  @keyframes enterFromRight {
    from {
      opacity: 0;
      transform: translateX(200px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes enterFromLeft {
    from {
      opacity: 0;
      transform: translateX(-200px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes exitToRight {
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(200px);
    }
  }

  @keyframes exitToLeft {
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(-200px);
    }
  }

  @keyframes scaleIn {
    from {
      opacity: 0;
      transform: rotateX(-30deg) scale(0.9);
    }
    to {
      opacity: 1;
      transform: rotateX(0deg) scale(1);
    }
  }

  @keyframes scaleOut {
    from {
      opacity: 1;
      transform: rotateX(0deg) scale(1);
    }
    to {
      opacity: 0;
      transform: rotateX(-10deg) scale(0.95);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes bgColorChange {
    from {
      background-color: yellow;
    }
    to {
      background-color: inherit;
    }
  }
}
