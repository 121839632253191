///////////////////////////////////////////////////////////////////
// Overriding example:
//      $bootstrap-legacy-variable: my-custom-somthing !important;
///////////////////////////////////////////////////////////////////
$enable-deprecation-messages: false;
$btn-border-radius: 0px !default;
$btn-border-radius-lg: 0px !default;
$btn-border-radius-sm: 0px !default;

// @import "../../../../bootstrap/scss/functions";
// @import "../../../../bootstrap/scss/mixins";

// ... your code goes here!

// override the bootstrap variables above this line!!!

@import "../../../../node_modules/bootstrap/scss/bootstrap";
