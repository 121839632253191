.#{shared-var("ModalDialogBS")} {
  &.centered {
    @extend .position-absolute;
    left: 0%;
    right: 0%;
  }

  @include media-breakpoint-down(lg) {
    .modal-90w {
      min-width: 90vw !important;
    }
  }
}
