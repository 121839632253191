.#{shared-var("ProductCategoryBS")} {
  &-header {
    @extend .mx-3;
    @extend .px-sm-3;

    .row {
      @extend .mx-0;
    }

    .media-object-title {
      @extend .text-left;
    }
  }

  &.fetching {
    //filter: blur(1px);
    opacity: 0.4;
  }

  @include media-breakpoint-up(xl) {
    .col-xl-2 {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
}
