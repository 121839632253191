.#{shared-var("MenuBarButtonBS")} {
  @extend .d-inline-block;
  @extend .px-md-3;
  @extend .px-1;
  @extend .text-center;
  @extend .cursor-pointer;

  // see https://uxmovement.com/mobile/optimal-size-and-spacing-for-mobile-buttons/
  min-width: 42px;

  svg {
    overflow: visible;
  }

  .title {
    @extend .ml-2;
    @extend .small;
    @extend .font-weight-bold;
    @extend .text-uppercase;
    vertical-align: middle;
  }

  &-badge {
    @extend .badge;
    @extend .badge-pill;
    // @extend .bg-success;
    @extend .text-dark;
    @extend .position-relative;
    @extend .align-items-center;
    right: 0.5rem;
    bottom: 0.75rem;
    height: fit-content;
    background-color: #91cd8a;
  }

  &.titleless {
    .#{shared-var("MenuBarButtonBS")}-badge {
      right: 0rem;
      top: -0.5rem;
      bottom: unset;
    }
  }

  &.search {
    @extend .py-2;
    @extend .px-1;
    @extend .m-0;
  }

  @include media-breakpoint-up(lg) {
    &.search {
      width: 100% !important;
    }
  }

  @include media-breakpoint-down(md) {
    &.user-button,
    &.admin-button {
      &:not(.#{shared-var("HasDropDownBS")}) {
        display: none !important;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    span.title {
      display: none !important;
    }

    &.search,
    &.#{shared-var("HasDropDownBS")} {
      & > span.title {
        display: none !important;
      }

      & > svg {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }
  }
}

a.favorite .#{shared-var("MenuBarButtonBS")} {
  @include media-breakpoint-down(xs) {
    & > span.title {
      display: none !important;
    }
  }
  &-badge {
    $heart-color-on: #e2264d;
    background-color: $heart-color-on !important;
  }
}
