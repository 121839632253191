.#{shared-var("PageTemplateBS") }-#{shared-var("BuyingGuidePageBS") } {
  .featured-product {
    &.#{shared-var("MediaBS")} {
      img {
        min-height: auto !important;
      }
    }
  }

  .card-item {
    .top-image {
      img {
        min-height: auto !important;
      }
    }
  }
}
