.#{shared-var("CardItemBS")} {
  .card-img-top {
    @extend .mx-auto;
    @extend .h-100;

    // fallback for older browsers
    max-width: 100%;
    max-height: 100%;

    // modern browser support
    max-width: stretch;
    max-height: stretch;
    max-height: fit-content;
    min-height: fit-content;
  }

  .top-image {
    @extend .overflow-hidden;
    @extend .mx-auto;
    @extend .d-block;
    /* &:not(.h-auto) {
      @extend .h-75;
    } */
  }

  &-body {
    flex-grow: 1;
    white-space: pre-wrap;
    &:not(.h-auto) {
      /*       $normalHeight: 33% !important;
      height: $normalHeight;
      min-height: fit-content;

      @include media-breakpoint-up(md) {
        height: 40% !important;
      }

      @include media-breakpoint-up(lg) {
        height: $normalHeight;
      }
 */
    }

    .card-title {
      h3 {
        font-size: inherit;
        @extend .font-weight-bold;
      }
    }
  }

  &.card {
    @extend .border-0;
  }
}
